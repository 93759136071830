interface Props {
  appDisplayName: string
}

export const AppBar = ({ appDisplayName }: Props) => {
  return (
    <div className="appBar">
      <h1>{appDisplayName}</h1>
    </div>
  )
}
