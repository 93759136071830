import './App.css'
import { AppBar } from 'components/controls/appBar'
import { AddSearchShowAllInput } from 'components/controls/addAndSearchInput'
import { TableHeaderElement } from 'components/controls/tableHeaderElement'
import { createTodo, Todo } from 'models/Todo'
import { TodoEntry } from 'components/controls/todoEntry'
import { useState, useEffect } from 'react'
import { SortOrder } from 'models/SortOrder'
import { UnderConstruction } from 'components/controls/underConstruction'

export const App = () => {
  const initTodoListModel: Todo[] = [createTodo('css'), createTodo('html')]

  const [todoListModel, setTodoListModel] = useState<Todo[]>(initTodoListModel)
  const [visibleTodoListModel, setVisibleTodoListModel] =
    useState<Todo[]>(todoListModel)
  const [textFilter, setTextFilter] = useState<string>('')
  const [sortOrderPriority, setSortOrderPriority] = useState<SortOrder>(
    SortOrder.Descending
  )
  const [sortOrderName, setSortOrderName] = useState<SortOrder>(SortOrder.None)

  // let showAll : boolean = false;
  const [showAll, setShowAll] = useState<boolean>(false)

  useEffect(() => {
    updateVisibleModel()
  }, [todoListModel, showAll, textFilter, sortOrderPriority, sortOrderName])

  function onAddClicked(todoName: string): void {
    const newList = [...todoListModel]
    newList.push(createTodo(todoName))
    setTodoListModel(newList)
  }

  function onInputChanged(input: string): void {
    console.log('input=' + input)
    setTextFilter(input)
  }

  function onDisplayAllCheckedChanged(checked: boolean): void {
    setShowAll(checked)
  }

  function updateVisibleModel(): void {
    let buf: Todo[] = [...todoListModel]

    if (textFilter !== '') {
      buf = buf.filter(todo => todo.text.includes(textFilter))
    }

    if (!showAll) {
      buf = buf.filter(todo => !todo.done)
    }

    if (sortOrderName === SortOrder.Ascending) {
      console.log('sort by name asc')
      buf.sort((a, b) => {
        const nameA = a.text.toUpperCase()
        const nameB = b.text.toUpperCase()
        if (nameA < nameB) return -1

        if (nameA > nameB) return 1

        return 0
      })
    } else if (sortOrderName === SortOrder.Descending) {
      console.log('sort by name desc')
      buf.sort((a, b) => {
        const nameA = a.text.toUpperCase()
        const nameB = b.text.toUpperCase()

        if (nameA < nameB) return 1
        else if (nameA > nameB) return -1

        return 0
      })
    } else if (sortOrderPriority === SortOrder.Ascending) {
      console.log('sort by prio asc')
      buf.sort((a, b) => {
        if (a.importance < b.importance) return -1
        else if (a.importance > b.importance) return 1

        return 0
      })
    } else if (sortOrderPriority === SortOrder.Descending) {
      console.log('sort by prio desc')
      buf.sort((a, b) => {
        if (a.importance < b.importance) return 1
        else if (a.importance > b.importance) return -1

        return 0
      })
    }

    setVisibleTodoListModel(buf)
  }

  function onSortOrderChanged(columnName: string, order: SortOrder): void {
    if (columnName === 'Wichtigkeit') {
      setSortOrderPriority(order)
      setSortOrderName(SortOrder.None)
    } else {
      setSortOrderName(order)
      setSortOrderPriority(SortOrder.None)
    }
  }

  function onTodoChanged(todo: Todo): void {
    let newList = [...todoListModel]

    let index = newList.findIndex(elem => elem.id === todo.id)

    if (todo.id === '') {
      newList.splice(index, 1)
    } else newList[index] = todo

    setTodoListModel(newList)
  }

  return (
    <div>
      <div className="outerContainer column">
        <AppBar appDisplayName="Simon Mauchle" />

        <UnderConstruction></UnderConstruction>

        {/* <div className="container column">
            <AddSearchShowAllInput
              inputChanged={onInputChanged}
              addClicked={onAddClicked}
              displayAllCheckedChanged={onDisplayAllCheckedChanged}
              defaultChecked={showAll}
              defaultInput={textFilter}
            />

            <div
              className={
                visibleTodoListModel.length === 0 ? 'hideElement' : 'tableTodos'
              }
            >
              <div className="placeHolder" />
              <TableHeaderElement
                sortOrder={sortOrderPriority}
                sortOrderChanged={onSortOrderChanged}
                elementName="Wichtigkeit"
              />
              <TableHeaderElement
                sortOrder={sortOrderName}
                sortOrderChanged={onSortOrderChanged}
                elementName="Aufgabe"
              />
              <div className="placeHolder" />

              {visibleTodoListModel.map(entry => (
                <TodoEntry
                  key={entry.id}
                  todo={entry}
                  todoChanged={onTodoChanged}
                />
              ))}
            </div>

            <div
              className={visibleTodoListModel.length !== 0 ? 'hideElement' : ''}
            >
              Keine Todos gefunden.
            </div>
          </div> */}
      </div>
    </div>
  )
}
