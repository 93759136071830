import { QtCanvas } from './QtCanvas'
import { useState, useEffect } from 'react'

interface Props {}

export const UnderConstruction = ({}: Props) => {
  const [wasmLoaded, setWasmLoaded] = useState<boolean>(false)
  const [loadingLabel, setLoadingLabel] = useState<string>('Just a Moment..')
  // let wasmLoaded: boolean = false

  const loadWASM = async () => {
    const script = document.createElement('script')
    script.src = 'qtloader.js'
    script.async = true

    document.body.appendChild(script)
    let canvas = document.querySelector('#qtcanvas')

    script.onload = () => {
      var qtLoader = QtLoader({
        canvasElements: [canvas],
        showLoader: function (loaderStatus: string) {
          console.log('load status=' + loaderStatus)
          if (loaderStatus.includes('Compiling')) {
            setLoadingLabel('Compile Webassembly..')
          }
          if (loaderStatus === 'Downloading') {
            setLoadingLabel('Fetch Data..')
          }
          // if(canvas !== null)
          //   canvas.style.display = 'none'
          // status.innerHTML = loaderStatus + '...'
        },
        showError: function (errorText: string) {
          console.log('error=' + errorText)
          setLoadingLabel('Error: ' + errorText)
          // status.innerHTML = errorText
          // canvas.style.display = 'none'
        },
        // showExit: function () {
        //   status.innerHTML = 'Application exit'
        //   if (qtLoader.exitCode !== undefined)
        //     status.innerHTML += ' with code ' + qtLoader.exitCode
        //   if (qtLoader.exitText !== undefined)
        //     status.innerHTML += ' (' + qtLoader.exitText + ')'
        //   canvas.style.display = 'none'
        // },
        showCanvas: function () {
          console.log('state show canvas')
          setWasmLoaded(true)
          // wasmLoaded = true
        },
      })
      let args: string[] = []
      qtLoader.loadEmscriptenModule('UnderConstructionWASM', args)
    }
  }

  useEffect(() => {
    console.log('uC useEffect')
    loadWASM()
  }, [])

  return (
    // <canvas
    //   className={'canvas'}
    //   id="qtcanvas"
    //   contentEditable="false"
    //   onContextMenu={e => e.preventDefault()}
    // ></canvas>
    <QtCanvas
      canvasVisible={wasmLoaded}
      loadingString={loadingLabel}
    ></QtCanvas>
  )
}
