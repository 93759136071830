import React from 'react'

interface Props {
  loadingString: string
  canvasVisible: boolean
}

export const QtCanvas = ({ canvasVisible, loadingString }: Props) => {
  console.log('QtCanvas visible=' + canvasVisible + ' str=' + loadingString)

  return (
    <React.Fragment>
      <div className={canvasVisible ? 'hideElement' : 'row'}>
        <div className={canvasVisible ? 'hideElement' : 'text textCenter'}>
          {loadingString}
        </div>
      </div>
      <canvas
        className={canvasVisible ? 'canvas' : 'canvas hideElement'}
        id="qtcanvas"
        contentEditable="false"
        onContextMenu={e => e.preventDefault()}
      ></canvas>
    </React.Fragment>
  )
}
